body {
    border-top: 8px solid #F6EDE6;
    font-family: arial, helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #624832;
}

a {
    color: #019BAB;
}

p a {
    text-decoration: underline;
}

p.smaller {
    font-size: 14px;
}

a:focus,
a:hover {
    color: #584D2F;
}

h1 {
    color: #624832;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
}

h1.with-border {
    border-bottom: 1px dotted #E5E0E0;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

h2 {
    color: #624832;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
}

h2.form-legend {
    color: #34AFBC;
    padding-bottom: 5px;
    margin-bottom: 6px;
    border-bottom: 1px solid #eee;
}

h3 {
    color: #624832;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

h4 {
    color: #019BAB;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 0;
}

h4:hover {
    cursor: pointer;
}

.clear {
    clear: both;
}

.logo {
    display: block;
    width: 330px;
    height: 70px;
    margin-bottom: 5px;
    margin-top: -28px;
}

.nav-top-ul {
    line-height: 31px;
    height: 33px;
    background-color: #F6EDE6;
    list-style: none;
    padding: 0 7px;
    font-size: 14px;
    float: right;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.nav-top-li {
    float: left;
}

.nav-top-a {
    color: #333333;
    display: block;
    padding: 0 7px;
}

.nav-top-li.active .nav-top-a,
.nav-top-li:focus .nav-top-a,
.nav-top-li:hover .nav-top-a,
.nav-top-a:focus,
.nav-top-a:hover {
    text-decoration: underline;
    color: #019BAB;
}

.nav-top-a.logout {
    color: red;
}

.nav-locale-li {
    float: left;
    position: relative;
    margin-left: 15px;
}

.nav-locale-a,
.nav-locale-a:focus,
.nav-locale-a:hover {
    display: block;
    padding: 0 7px;
    text-decoration: none;
}

.nav-locale-child-a {
    padding-right: 7px;
}

.nav-locale-child-a img {
    height: 14px;
    width: 20px;
}

.search-and-cart {
    padding-top: 7px;
}

.search-small {
    display: none;
}

.form-control-search {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px !important;
}

.twitter-typeahead {
    width: 100% !important;
    float: left !important;
}

.loading {
    background: transparent url('/img/loading.gif') no-repeat scroll right center content-box;
    background-size: contain;
}

.tt-query,
.tt-hint {
    width: 396px;
    height: 30px;
    padding: 8px 12px;
    line-height: 30px;
    border: 2px solid #ccc;
    border-radius: 8px;
    outline: none;
}

.tt-query {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-input {
    background-color: white !important;
}

.tt-menu {
    top: 21px;
    width: 100%;
    border: 1px solid #66AFE9;
    background-color: #fff;
    border-radius: 0;
    outline: 0px none;
    border-top: none;
}

.tt-suggestion {
    padding: 2px 12px;
    line-height: 25px;
    height: 25px;
}

.tt-suggestion:hover,
.tt-suggestion.tt-cursor {
    cursor: pointer;
    background-color: #F6EDE6;
}

.tt-suggestion span.title {
    max-width: 290px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}

.tt-suggestion span.number {
    color: grey;
    font-size: 12px;
    float: right;
    display: inline-block;
}

.tt-spinner {
    position: absolute;
    right: 45px;
    top: 8px;
    z-index: 2;
    font-size: 17px;
    opacity: 0.5;
    display: none;
}

.btn-search {
    background: url('/assets/images/icon_search.png') 6px 5px no-repeat #9BBC62;
    width: 35px;
    height: 34px;
}

.btn-search:focus,
.btn-search:hover {
    background-color: #584E2F;
}

.cart-info {
    border-radius: 3px;
    color: white;
    display: block;
    float: left;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 500;
    height: 34px;
    line-height: 19px;
    text-align: center;
    text-indent: 2px;
    width: 34px;
    background: url('/assets/images/icon_cart.png') 3px -30px no-repeat #019BAB;
    float: right;
    margin-left: 13px;
}

.cart-info:focus,
.cart-info:hover {
    background-color: #9bbc62;
    text-decoration: none;
    color: white;
}

nav.main {
    min-height: 48px;
    line-height: 44px;
    background-color: #029BAB;
    margin-top: 10px;
    width: auto;
    padding-left: 0;
    padding-right: 0;
}

/* clearfix */
nav.main:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

* html nav.main {
    zoom: 1;
}

/* IE6 */
*:first-child + html nav.main {
    zoom: 1;
}

/* IE7 */

nav.main .nav-bars {
    display: none;
}

.nav-main-ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}

.nav-main-li {
    float: left;
    padding: 4px 0 0;
    margin: 0 1px;
    position: relative;
    width: auto;
}

.nav-main-orderform {
    float: right;
    margin: 1px 4px 0 1px;
}

.nav-main-a {
    color: white;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    display: block;
    padding: 0 8px;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.nav-main-orderform a {
    background-color: #374B88;
    border-radius: 3px;
    line-height: 32px;
    margin-top: 4px;
}

.nav-main-a:focus {
    color: #584E2F;
    text-decoration: none;
}

.nav-main-li.active .nav-main-a,
.nav-main-li:focus .nav-main-a,
.nav-main-li:hover .nav-main-a,
.nav-main-a:focus,
.nav-main-a:hover {
    color: #584E2F;
    background-color: white;
    text-decoration: none;
}

.nav-main-a-home {
    text-indent: -9999px;
    width: 41px;
    height: 18px;
    margin-top: 11px;
    overflow: hidden;
    background: url('/assets/images/icon_home.png') top center no-repeat;
}

.nav-main-li.active .nav-main-a-home,
.nav-main-li:focus .nav-main-a-home,
.nav-main-li:hover .nav-main-a-home,
.nav-main-a-home:focus,
.nav-main-a-home:hover {
    background: url('/assets/images/icon_home.png') bottom center no-repeat transparent;
}

.nav-main-a-expand {
    display: none;
}

.nav-main-child-ul {
    z-index: 999;
    background: white;
    visibility: hidden;
    border-right: 1px solid #019BAB;
    border-bottom: 1px solid #019BAB;
    border-left: 1px solid #019BAB;
    position: absolute;
    left: -1px;
    top: 48px;
    list-style: none;
    padding: 10px 8px;
    font-family: arial, helvetica, sans-serif;
    font-size: 14px;
    line-height: 24px;
}

.nav-main-child-li {
    white-space: nowrap;
}

.nav-main-child-a {
    color: #594E30;
    display: block;
    -webkit-border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.nav-main-child-li.active .nav-main-child-a,
.nav-main-child-li:focus .nav-main-child-a,
.nav-main-child-li:hover .nav-main-child-a,
.nav-main-child-a:focus,
.nav-main-child-a:hover {
    color: #019BAB;
    text-decoration: underline;
}

.nav-main-li:focus .nav-main-child-ul,
.nav-main-li:hover .nav-main-child-ul {
    visibility: visible;
}

.nav-main-li > .nav-main-child-ul {
    min-width: 100%;
    min-width: calc(100% + 2px);
}

.nav-secondary-lines {
    color: #e2e2e2;
}

.nav-secondary-lines:last-of-type {
    display: none;
}

.breadcrumb {
    background: none;
    border-radius: 0;
    padding: 0;
    margin: 10px 0;
    font-size: 14px;
    color: #333333;
}

.breadcrumb a {
    color: #333333;
}

.breadcrumb a:focus,
.breadcrumb a:hover {
    color: #019BAB;
    text-decoration: underline;
}

.intro {
    padding: 20px 20px 10px 20px;
    background: #F6EDE6;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 15px;
}

.intro-home {
    margin-top: 20px;
    background: #FFFFFF;
}

.product-small-cnt,
.news-small-cnt {
    margin-bottom: 30px;
}

.brand-small-a,
.product-small-a,
.news-small-a {
    border: 1px solid #E1DBDB;
    display: block;
    height: 290px;
    width: 262px;
    max-width: 100%;
    position: relative;
    transition: border 200ms ease-out;
}

.brand-small-a {
    height: 133px;
}

.news-small-a {
    height: 225px;
    width: 100%;
}

.product-small-a:hover,
.product-small-a:focus,
.news-small-a:hover,
.news-small-a:focus {
    border-color: #019BAB;
}

.brand-small-img,
.product-small-img,
.news-small-img {
    width: 100%;
    max-width: 100%;
    height: auto;
    line-height: 100%;
    color: #999 !important;
    background-color: white;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
}

.product-small-img img,
.news-small-img img {
    max-width: 100%;
}

.brand-small-img img {
    display: inline-block;
}

.news-small-img {
    line-height: 153px;
}

.product-small-title {
    color: #624832;
    text-align: center;
    position: absolute;
    left: 0;
    top: 200px;
    width: 100%;
    font-size: 14px;
    padding: 0 8px;
}

.news-small-title {
    color: white;
    text-align: center;
    position: absolute;
    left: 0;
    top: 153px;
    width: 100%;
    height: 70px;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    padding: 15px 10px 15px 10px;
    background: rgba(1, 155, 171, 0.8);
}

.brand-category-title,
.product-small-price {
    color: #019bab;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    left: 10px;
    bottom: 10px;
}

.brand-category-title {
    left: 0;
    width: 100%;
    text-align: center;
}

.product-small-go,
.product-small-cart-add {
    position: absolute;
    right: 10px;
    bottom: 10px;
    height: 36px;
    width: 36px;
    text-indent: -999px;
    overflow: hidden;
    border: 0;
    background: url('/assets/images/icon_cart_add.png') top center no-repeat;
}

.product-small-cart-add:focus,
.product-small-cart-add:hover {
    background: url('/assets/images/icon_cart_add.png') bottom center no-repeat transparent;
}

.product-small-go {
    border-radius: 4px;
    background: url("/assets/images/icon_arrow.png") no-repeat scroll 14px 9px #019BAB;
}

.product-small-go:focus,
.product-small-go:hover {
    background-color: #846A54;
}

.carousel.slide .carousel-inner {
    width: 100%;
    height: 290px;
    overflow: hidden;
}

.carousel.slide {
    margin-bottom: 20px;
    position: relative;
}

.carousel.slide:before {
    content: "";
    height: 100%;
    position: absolute;
    width: 15px;
    background-color: #FFFFFF;
    z-index: 1;
    left: 0;
}

.carousel.slide:after {
    content: "";
    height: 100%;
    position: absolute;
    width: 15px;
    background-color: #FFFFFF;
    z-index: 1;
    right: 0;
    top: 0;
}

.carousel-nav {
    background: url("/assets/images/icon_arrow.png") no-repeat scroll 11px 6px #9bbc62;
    border-radius: 3px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 130px;
    width: 30px;
    z-index: 2;
}

.carousel-nav.carousel-nav-prev {
    background-position: 7px -21px;
    left: 0;
}

.carousel-nav:focus,
.carousel-nav:hover {
    background-color: #019BAB;
}

.brand-logo-small {
    display: block;
    margin-right: 30px;
    background-color: #e2e2e2;
    color: #888;
    line-height: 80px;
    text-align: center;
    font-size: 13px;
    margin-bottom: 30px;
}

.brand-logo-large {
    width: 260px;
    height: 126px;
    display: block;
    background-color: #e2e2e2;
    color: #888;
    line-height: 126px;
    text-align: center;
    font-size: 13px;
}

.brand-logo-large img {
    vertical-align: top;
}

.brand-logo-small:active,
.brand-logo-small:hover {
    background-color: #eee;
    text-decoration: none;
    color: #888;
}

.brand-logo-small:last-child {
    margin-right: 0;
}

.brand-logo-small-img {
    display: block;
}

.addthis_sharing_toolbox {
    position: absolute;
    right: 16px;
    top: 4px;
}

.product-large-img {
    margin: 7px auto 20px;
    width: 360px;
    max-width: 100%;
    height: auto;
    line-height: 100%;
    color: #999 !important;
    background-color: #f2f2f2;
    text-align: center;
}

.product-large-img img {
    max-width: 100%;
}

.no-product-img {
    display: block;
    line-height: 240px;
}

.product-large-preview-cnt {
    background: #F6EDE6;
    padding: 6px 0 0 6px;
    text-align: left;
    margin-bottom: 1em;
}

.product-large-preview-img {
    width: 79px;
    height: 79px;
    margin: 0px 6px 6px 0;
}

.product-large-preview-img:hover {
    cursor: pointer;
}

.product-download {
    width: 79px;
    line-height: 79px;
    margin: 0px 6px 6px 0;
    background-color: white;
    text-align: center;
    display: inline-block;
}

.product-download i {
    font-size: 40px;
    display: inline-block;
    vertical-align: middle;
    line-height: 79px;
}

.product-large-price {
    color: #019bab;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: normal;
    margin-right: 10px;
    display: inline-block;
}

.product-large-selector {
    display: inline-block;
    width: auto;
    max-width: 200px;
    margin-right: 15px;
    padding-top: 3px !important;
}

.product-stock {
    font-size: 14px;
}

.product-stock-yes {
    color: #9BBC62;
}

.product-stock-no {
    color: #f9342f;
}

.cart-header-row {
    color: #333333;
    font-size: 14px;
    margin: 0 0 20px 0;
}

.cart-header-row > div {
    background: #F6EDE6;
    height: 30px;
    line-height: 30px;
}

.cart-product-row {
    min-height: 75px;
    line-height: 75px;
}

.cart-widget-row {
    font-size: 14px;
}

.product-cart-img {
    width: 75px;
    height: 75px;
    margin-top: 7px;
    border: 1px solid #E5DEDE;
    float: left;
    margin-right: 20px;
    line-height: 75px;
    color: #999 !important;
    background-color: #f2f2f2;
    text-align: center;
    position: relative;
}

.product-cart-img img {
    width: 73px;
    height: 73px;
    position: absolute;
    top: 0;
    left: 0;
}

.cart-product-title {
    font-size: 16px;
    color: #624832;
    margin-top: 26px;
    line-height: 20px;
}

.cart-article-number {
    font-size: 16px;
    color: #ADACAC;
    line-height: 20px;
}

.cart-price {
    color: #624832;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
}

.row-total {
    font-size: 22px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: normal;
}

.row-total-small {
    font-weight: bold;
}

.product-cart-price {
    color: #019bab;
}

.cart-amount {
    display: inline-block;
    width: 70px;
    text-align: center;
}

.cart-remove {
    border-radius: 3px;
    color: white;
    display: inline-block;
    height: 34px;
    width: 34px;
    line-height: 1px;
    border: 0;
    position: relative;
    top: 11px;
    background: url('/assets/images/icon_cross.png') 8px 8px no-repeat #F6EDE6;
    margin: 1px 0 -12px 10px;
}

.cart-remove:focus,
.cart-remove:hover {
    background: url('/assets/images/icon_cross.png') 8px -41px no-repeat #019BAB;
    cursor: pointer;
}

.cart-pay-row {
    margin-top: 40px;
    margin-bottom: 35px;
}

.cart-continue {
    line-height: 35px;
    display: block;
    transition: all 0.2s linear;
}

.cart-continue:hover,
.cart-continue:focus,
.cart-continue:active {
    padding-right: 5px;
    text-decoration: none;
    color: #9BBC62;
}

#salesaddresses-filter {
    position: absolute;
    right: 83px;
    top: 10px;
    background: white;
    z-index: 1;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
}

#salesaddresses-filter .checkbox {
    margin: 1px 0;
}

#salesaddresses-map {
    width: 100%;
    height: 500px;
    border: 1px solid #DFD9D9;
    z-index: 0;
}

.cart-steps-ul {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #019bab;
}

.cart-steps-li {
    list-style: none;
    display: inline-block;
    padding: 3px 10px;
    margin: 0 5px 0 0;
    color: lightgrey;
}

.cart-steps-li:first-child {
    padding-left: 0;
}

.cart-steps-a {
    display: block;
}

.cart-steps-a:hover {
    text-decoration: none;
}

.cart-steps-li.active {
    background: #019bab;
    color: white;
    border: 1px solid #019bab;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.inline-form {
    display: inline-block;
}

hr.blue {
    border-top: 1px solid #34AFBC;
    margin: 0;
}

hr.grey {
    border-top: 1px solid #DFD9D9;
    margin: 0;
}

hr.dotted {
    border-top: 1px dotted #E5E0E0;
}

.login-row-hr {
    display: none;
}

.hr-extra-margin {
    margin-top: 30px;
    margin-bottom: 30px;
}

footer {
    font-size: 13px;
    margin-top: 20px;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer ul a {
    color: #624832;
}

.alert ul {
    padding: 10px 0 0 15px;
}

.alert-no-margin {
    margin-bottom: 0;
}

.subfooter {
    color: #afa9a9;
    font-size: 11px;
}

.subfooter a {
    color: #afa9a9;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.float-right {
    float: right;
}

label {
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: normal;
}

label:after {
    content: ": ";
}

label.radio:after,
#salesaddresses-filter label:after,
.wants_mailings label:after,
.wants_invoices label:after,
.wants_orders label:after {
    content: "";
}

.radio-group label {
    display: block;
}

.row-margin-top {
    margin-top: 20px;
}

.row-margin-footer {
    margin-bottom: 20px;
}

.radio {
    margin: 0 8px 5px 0;
    display: inline-block;
    padding: 2px 1px 2px 4px;
}

.radio-address {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
}

.radio label {
    padding-left: 25px;
}

.radio-address label {
    display: block;
    height: 100%;
    padding-left: auto;
}

.form-group {
    margin-bottom: 5px;
}

.form-control {
    font-size: 16px;
    color: #624832;
    padding: 6px 12px 5px 12px;
}

.radio.selected {
    background: #f6ede6;
    border-radius: 5px
}

.radio input[type="radio"] {
    margin-top: -5px;
    margin-left: -23px;
}

.radio-address input[type="radio"] {
    margin-top: 4px;
    margin-left: -20px;
}

label.radio input[type="radio"] {
    width: auto;
}

.help-block {
    font-size: 14px;
    line-height: 17px;
    margin-top: 2px;
}

.tab-block {
    padding-top: 20px;
    padding-bottom: 20px;
}

#account table.table tr:first-child td {
    border-top: 0;
}

#account table.table tr td:first-child {
    width: 150px;
    font-weight: bold;
}

.tr-user-spacer,
.span-user,
.cart-product-span {
    display: none;
}

.btn {
    display: inline-block;
    border: 0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: normal;
    border-radius: 3px;
    padding: 8px 10px;
}

.btn-blue {
    color: white;
    background-color: #019BAB;

}

.btn-blue:focus,
.btn-blue:hover {
    background-color: #9bbc62;
    text-decoration: none;
    color: white;
}

.btn-default {
    color: white;
    background-color: #c2c2c2;
}

.btn-default:focus,
.btn-default:hover {
    background-color: #999;
    text-decoration: none;
    color: white;
}

.btn-success {
    color: white;
    background-color: #9bbc62;
}

.btn-default:focus,
.btn-default:hover {
    background-color: #999;
    text-decoration: none;
    color: white;
}

.btn-payment-method {
    margin-bottom: 15px;
    background-color: white;
    border: 1px solid #d2d2d2;
    width: 100%;
    height: 80px;
    color: #888;
    padding: 10px;
    font-size: 13px;
    position: relative;
}

.btn-payment-method img {
    display: block;
    margin: 0 auto;
}

.btn-payment-method span {
    display: block;
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    text-align: center;
    overflow: hidden;
}

.btn-payment-method:hover {
    background: #5CB85C;
    color: white;
    border-color: #4cae4c;
}

.btn-darkblue {
    color: white;
    background-color: #2C4985;
}

.btn-darkblue:focus,
.btn-darkblue:hover {
    background-color: #9bbc62;
    text-decoration: none;
    color: white;
}

.btn-icon-cart,
.btn-icon-cart:focus {
    text-indent: 30px;
    background-image: url('/assets/images/icon_cart.png');
    background-position: 6px 3px;
    background-repeat: no-repeat;
}

.btn-icon-arrow-right,
.btn-icon-arrow-right:focus {
    padding-right: 40px;
    background-image: url('/assets/images/icon_arrow.png');
    background-attachment: scroll;
    background-position: right 8px;
    background-repeat: no-repeat;
    border-right: 8px solid transparent;
}

.btn-icon-retailer,
.btn-icon-retailer:focus {
    text-indent: 30px;
    background-image: url('/assets/images/icon_retailer.png');
    background-position: 6px 6px;
    background-repeat: no-repeat;
}

.btn-icon-customer,
.btn-icon-customer:focus {
    text-indent: 30px;
    background-image: url('/assets/images/icon_customer.png');
    background-position: 6px 4px;
    background-repeat: no-repeat;
}

.btn-icon-key,
.btn-icon-key:focus {
    text-indent: 30px;
    background-image: url('/assets/images/icon_key.png');
    background-position: 6px 5px;
    background-repeat: no-repeat;
}

.btn .glyphicon {
    top: 2px;
}

.btn span.glyphicon {
    margin-right: 10px;
}

.btn span.glyphicon-remove {
    margin-right: 0;
}

.nav-tabs > .active > a, .nav-tabs > .active > a:hover {
    outline: none;
}

#new-invoice-address,
#new-delivery-address {
    display: none;
}

#new-invoice-address .help-block,
#new-delivery-address .help-block {
    display: none;
}

#new-invoice-address .has-error input,
#new-delivery-address .has-error input {
    background-color: #FFD7D6;
}

.news-item-img {
    width: 260px;
    height: 200px;
    line-height: 200px;
    color: #999 !important;
    text-align: center;
}

.news-item-img img {
    max-width: 100%;
    vertical-align: top;
}

.news-item-img-container {
    padding-bottom: 0.5em;
    padding-left: 0px;
    padding-right: 0px;
}

.news-item-date {
    font-size: 12px;
    color: #777;
    margin: -15px 0 10px 0;
}

.news-item-ul {
    padding-left: 0px;
    list-style: none;
}

.news-item-li:first-child,
.news-item-li:nth-of-type(2) {
    display: none;
}

.news-item-li-date {
    color: #666;
}

.new-address-tip {
    font-size: 11px;
    font-style: italic;
    margin-left: -20px;
}

td {
    vertical-align: middle !important;
}

.row-orderform-category {
    margin: 0px 15px;
    border-bottom: 1px solid #ddd;
    height: 50px;
    line-height: 50px;
    user-select: none;
    background-color: #e6e6e6;
}

.row-orderform {
    height: 57px;
    line-height: 57px;
    margin: 0 15px;
    border-bottom: 1px solid #ddd;
}

.row-orderform-category div:nth-child(1),
.row-orderform div:nth-child(1) {
    padding-left: 5px;
}

.row-orderform-category:hover {
    cursor: pointer;
}

.row-orderform-category.collapsed {
    background-color: white;
}

.row-orderform-category.collapsed:hover {
    background-color: #f2f2f2;
}

.row-orderform-category.collapsed strong {
    font-weight: normal;
}

.row-orderform-category.collapsed div:nth-child(n+2) {
    visibility: hidden;
}

.row-orderform:hover {
    background-color: #f8f8f8;
}

.product-orderform-img {
    width: 52px;
    height: 52px;
    display: block;
    background-color: #f2f2f2;
    border: 1px solid #eee;
    line-height: 48px;
    text-align: center;
    margin: 2px 0;
    padding: 0 !important;
}

.product-orderform-img img {
    width: 50px;
    height: 50px;
    line-height: 50px;
}

.product-orderform-amount {
    display: inline-block;
    width: 70px;
    text-align: center;
}

.product-orderform-product {
    line-height: 20px;
    margin-top: 10px;
    display: block;
}

.product-orderform-an {
    line-height: 15px;
    font-size: 13px;
    color: #a9a9a9;
}

.intro img, p img {
    max-width: 100%;
    height: auto;
}

label.radio label {
    padding-left: 0;
}

.glyphicon.spinning {
    animation: spin 1s infinite linear;
    -webkit-animation: spin2 1s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}

@-webkit-keyframes spin2 {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.label {
    vertical-align: middle;
    padding: 0.2em 0.6em;
}

.text-small {
    font-size: 13px;
    opacity: 0.8;
}

@media print {
}

@media (min-width: 1200px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
    .nav-main-a {
        font-size: 14px;
        padding: 0 5px;
    }
}

@media (min-width: 640px) and (max-width: 991px) {
    nav.main .nav-main-ul {
        float: left;
        width: 33.3333%;
        padding: 15px 3% 0 0;
    }

    nav.main .nav-main-li {
        width: 100%;
    }

    .nav-main-li,
    .nav-main-li.right {
        float: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 640px) and (max-width: 768px) {
    .col-xs-6.fav-product .product-small-a {
        width: 100%;
    }
}

@media (min-width: 360px) and (max-width: 550px) {
    .news-small-a {
        height: 239px;
    }

    .news-small-title {
        height: 84px;
    }
}

@media (max-width: 1279px) {
    .logo {
        width: 100%;
        height: auto;
        margin-top: -11px;
    }

    .logo img {
        max-width: 100%;
    }

    .brand-logo-small-img {
        width: 100%;
        height: auto;
    }

    .product-large-preview-img {
        width: 62px;
        height: 62px;
    }

    .hide1279 {
        display: none;
    }
}

@media (max-width: 1199px) {
    .brand-logo-large {
        margin-bottom: 1em;
    }

    .news-row-hr {
        display: none;
    }

    .news-row-btn {
        margin-bottom: 15px;
    }

    .news-item-img {
        margin-bottom: 15px;
        width: 100%;
        height: auto;
    }

    .news-item-img-single {
        width: 260px;
        height: 200px;
    }

    .col-md-3.col-sm-6.footer-social img {
        margin-left: 5px !important;
    }
}

@media (max-width: 991px) {
    .nav-main-sm-container {
        background-color: #029BAB;
        padding: 0 15px 0 0;
    }

    .search-small {
        display: inline-block;
        float: right;
        margin: 7px 7px 0 0;
        width: calc(100% - 65px);
    }

    .search-and-cart {
        display: none;
    }

    .nav-top-ul {
        height: auto;
        margin-bottom: 15px;
    }

    nav.main {
        line-height: inherit;
    }

    nav.main .nav-bars {
        line-height: 48px;
        display: inline-block;
        cursor: pointer;
        font-size: 1.5em;
        color: #FFF;
        padding: 0 15px;
    }

    .nav-main-ul {
        padding: 15px 0 0 0;
    }

    .nav-main-li {
        padding: 0 0 15px 15px;
        margin: 0;
        position: relative;
        float: left;
        clear: left;
        width: 50%;
    }

    .nav-main-li.right {
        float: right;
        clear: right;
    }

    .nav-main-li.middle {
        float: none;
        clear: both;
        padding: 0;
    }

    .nav-main-home {
        width: 100%;
        display: none;
    }

    .nav-main-orderform {
        float: right;
        clear: right;
        text-align: left;
        padding-left: 15px;
    }

    .nav-main-orderform .nav-main-a {
        padding: 0 8px;
        display: inline-block;
    }

    .nav-main-li.nav-main-orderform:hover .nav-main-a,
    .nav-main-li.nav-main-orderform .nav-main-a:active,
    .nav-main-li.nav-main-orderform .nav-main-a:focus,
    .nav-main-li.nav-main-orderform .nav-main-a:visited {
        background-color: #374B88;
    }

    nav.main .nav-main-li.nav-main-orderform:hover .nav-main-a:hover,
    nav.main .nav-main-li.nav-main-orderform .nav-main-a:hover {
        color: #fff;
        background-color: #584E2F;
    }

    .nav-main-a-home {
        width: 24px;
    }

    .nav-main-a {
        padding: 0;
    }

    .nav-main-a,
    .nav-main-child-a,
    .nav-main-li:hover .nav-main-a,
    .nav-main-li.active .nav-main-a,
    .nav-main-a:active,
    .nav-main-a:visited,
    .nav-main-a:focus,
    .nav-main-a-expand:visited,
    .nav-main-a-expand:focus,
    .nav-main-a-expand:active,
    .nav-main-a-expand {
        background-color: transparent;
        color: #fff;
    }

    nav.main a:hover,
    nav.main a:active,
    nav.main a:focus,
    nav.main .nav-main-li:hover .nav-main-a:hover,
    nav.main .nav-main-li:hover .nav-main-a:active,
    nav.main .nav-main-li:hover .nav-main-a:focus,
    nav.main .nav-main-a:hover,
    nav.main .nav-main-a:active,
    nav.main .nav-main-a:focus,
    nav.main .nav-main-child-a:hover,
    nav.main .nav-main-child-a:active,
    nav.main .nav-main-child-a:focus,
    .nav-main-a-expand:hover {
        color: #584E2F;
        text-decoration: none;
    }

    nav.main a:visited,
    nav.main .nav-main-a:visited,
    nav.main .nav-main-child-a:visited {
        color: #fff;
    }

    .nav-main-a-expand {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 5px 5px 5px;
        font-size: 1.5em;
        cursor: pointer;
        z-index: 999;
        width: 25px;
        text-align: center;
    }

    .nav-main-child-ul {
        width: auto;
        z-index: 1;
        visibility: visible;
        border: 0;
        position: static;
        padding: 0 0 0 4px;
        background-color: transparent;
    }

    .nav-main-child-a {
        white-space: normal;
    }

    .nav-secondary-lines {
        color: #029BAB;
        line-height: 10px;
    }

    .news-small-cnt:last-child {
        display: none;
    }

    .news-item-text-container {
        padding-left: 0;
        padding-right: 0;
    }

    .news-highlighted:last-of-type {
        display: none;
    }

    .news-item-li:nth-of-type(2) {
        display: block;
    }

    .cart-price {
        font-size: 16px;
    }

    .cart-product-title,
    .cart-article-number {
        font-size: 14px;
    }

    .cart-product-title {
        margin-top: 9px;
    }

    #new-delivery-btn {
        margin-bottom: 14px;
    }

    footer {
        padding-bottom: 1em;
    }

    footer.col-md-3 {
        text-align: center;
    }
}

@media (max-width: 768px) {

    .subfooter {
        margin-top: 1em;
    }

    .login-row-hr {
        display: block;
        margin: 30px 15px;
    }

    .news-small-cnt:last-child {
        display: block;
    }

    .news-item-li:first-child {
        display: inline;
    }

    .news-row .col-md-12:last-of-type {
        display: none;
    }

    .news-row .news-highlighted:nth-last-of-type(2) {
        display: none;
    }

    .news-item-li:first-of-type {
        display: block;
    }

    .product-cart-img {
        display: none;
    }

    .carousel-nav {
        right: 14px;
    }

    .carousel-nav.carousel-nav-prev {
        left: 14px;
    }
}

@media (max-width: 640px) {
    .nav-main-orderform {
        white-space: nowrap;
    }

    .cart-product-row {
        line-height: normal;
    }

    .cart-product-row .align-right,
    .user-invoice-date .text-right {
        text-align: left;
    }

    .cart-product-hr {
        margin: 14px 0;
    }

    .cart-steps-li:first-child {
        padding-left: 10px;
    }

    .row-total {
        font-size: 16px;
    }

    .row-delivery-cost {
        font-size: 12px;
        margin-bottom: 14px;
    }

    .cart-product-fullwidth {
        padding: 0;
        width: 100%;
        margin: 0 15px 14px;
    }

    #client-user-tabs .user-tab-li,
    .cart-steps-li {
        width: 100%;
        float: none;
    }

    #client-user-tabs .user-tab-a {
        border: 1px solid #DDD;
    }

    #client-user-tabs .active .user-tab-a {
        background-color: #DDD;
    }

    .user-ordered-products {
        margin-top: 14px;
    }

    .user-invoice-date {
        margin-bottom: 14px;
    }

    .user-invoice-p {
        margin-bottom: 0;
    }

    .table-user,
    .thead-user,
    .tbody-user,
    .tr-user,
    .th-user,
    .td-user-addresses,
    .td-user-orders,
    .td-user-invoices,
    .td-user-order,
    .td-user-invoice {
        display: block;
    }

    .thead-user .tr-user,
    .cart-header-row {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .tr-user .td-user-addresses,
    .tr-user .td-user-orders,
    .tr-user .td-user-invoices,
    .tr-user .td-user-order,
    .tr-user .td-user-invoice,
    .cart-product-title,
    .cart-article-number,
    .product-cart-price,
    .cart-product-form {
        position: relative;
        padding-left: 25%;
        text-align: left;
        border-bottom: 0;
    }

    .tr-user .td-user-invoice {
        padding-left: 35%;
    }

    .tr-user .td-user-orders {
        padding-left: 40%;
    }

    .tr-user .td-user-invoices {
        padding-left: 45%;
    }

    .td-user-invoices .btn {
        font-size: 14px;
    }

    .span-user,
    .cart-product-span {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
    }

    .span-user {
        padding: 8px 0 8px 8px;
    }

    .td-user-invoice .span-user {
        padding-left: 0px;
    }

    .cart-product-span {
        font-size: 12px;
        color: #624832;
        line-height: 20px;
        font-family: arial, helvetica, sans-serif;
    }

    .td-user-addresses:nth-of-type(3):before {
        padding-bottom: 10px;
    }

    .tr-user-spacer {
        display: block;
    }

    .tr-user-spacer:last-of-type {
        display: none;
    }

    .tr-user-spacer .td-spacer {
        border-top: 0;
        border-bottom: 0;
        padding: 1em;
    }
}

@media (max-width: 520px) {
    footer .col-xs-6 {
        width: 100%;
    }

    footer,
    .subfooter {
        text-align: center;
    }
}

@media (max-width: 450px) {

    .col-xs-4.home-brand-logo {
        width: 50%;
    }

    .col-xs-6 {
        width: 100%;
    }

    .product-small-a {
        margin: 0 auto;
    }

    .fav-products-h2 {
        text-align: center;
    }

    .nav-main-li,
    .nav-main-li.right,
    .nav-main-orderform {
        float: none;
        padding-left: 15px;
    }
}

@media (max-width: 359px) {

    .row-delivery-cost .col-xs-9,
    .row-total .col-xs-9 {
        width: 70%;
    }

    .row-delivery-cost .col-xs-3,
    .row-total .col-xs-3 {
        width: 30%;
    }

    .cart-article-number {
        font-size: 12px;
    }
}